<template>
  <div>
    <el-table v-loading="loading" :data="list">
      <el-table-column prop="id" label="编号"></el-table-column>
      <el-table-column prop="type" :formatter="(item)=>item.type === 1 ? '增加' : '减少'" label="变动类型"></el-table-column>
      <el-table-column prop="fee" label="变动金额"></el-table-column>
      <el-table-column prop="tips" label="变动记录文本" show-overflow-tooltip></el-table-column>
      <el-table-column prop="created_at" label="变动时间" show-overflow-tooltip>
        <template #default="s">
          <div>{{s.row.created_at | date}}</div>
        </template>
      </el-table-column>
      <el-table-column prop="rate" :formatter="(item)=>item.type === 2 ? '':item.rate + '%' " label="佣金比例快照"></el-table-column>
      <el-table-column prop="active_id" :formatter="(item)=>item.type === 2 ? '':item.active_id " label="活动编号"></el-table-column>
      <el-table-column prop="active.title" label="活动名称" show-overflow-tooltip></el-table-column>
      <el-table-column prop="order.order_no" label="订单号" show-overflow-tooltip></el-table-column>
      <el-table-column prop="order.member.avatar" label="订单用户头像">
        <template #default="s">
          <div v-if="s.row.order && s.row.order.member">
            <img :src="s.row.order.member.avatar | tomedia" style="height: 3rem;width: 3rem;border-radius: .5rem" alt="">
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="order.member.nickname" label="昵称" show-overflow-tooltip></el-table-column>
      <template #append>
        <el-pagination style="padding: .5rem" background layout="total,prev, pager,next"
                       :total="total" :page-size="15" :current-page="page" @current-change="pageChange">
        </el-pagination>
      </template>
    </el-table>
  </div>
</template>

<script>
export default {
  name: "balanceChange",
  data(){
    return{
      loading:false,
      page:1,
      list:[],
      total:0,
      size:0
    }
  },
  mounted() {
    this.load();
  },
  methods:{
    pageChange(e) {
      this.page = e;
      this.load();
    },
    load(){
      this.loading = true;
      this.$api.plugin.agent.adminBalanceChange({
        page:this.page,
        agent_id:parseInt(this.$route.query.agent_id)
      }).then(res=>{
        this.loading = false;
        this.list = res.list;
        this.total = res.total;
        this.size = res.size;
      })
    }
  }
}
</script>

<style scoped>

</style>